.header-rounded {
  border-bottom: #3c3a3a;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
}
.btn-bg-color {
  color: #fff;
  background-color: #aa2b33;
  border-color: #aa2b33;
}
.btn-bg-color:hover {
  color: #fff;
  background-color: #aa2b25;
  border-color: #aa2b25;
  box-shadow: 0 1px 10px rgba(255, 73, 84, 0.4);
}

.btn-bg-color:focus,
.btn-bg-color.focus {
  color: #fff;
  box-shadow: 0 0 0 0 rgba(255, 73, 84, 0.5);
}
